import React, { useContext, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import ServiceAuth from '../services/service_auth'
import { AuthContext } from '../Context/authProvider';

function Login() {

    const {state} = useLocation()
    const navigate = useNavigate()
    const [authError, setAuthError] = useState('')
    const {auth, authDispatch} = useContext(AuthContext)
    const [searchParams, setSearchParams] = useSearchParams();
    const success = searchParams.get("success")
    const error = searchParams.get("error")
    return (
        <div className="d-flex justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-4">
                {/* <div className="alert alert-info">Les bons de commandes pour toile PVC (Store enrouleur et Store Banne) sont à présent disponible,
                 retrouvé les dans la catégorie confection PVC.</div> */}
                <h1 className="text-center mt-2">Connexion</h1>
                <Formik
                    initialValues={{ login: '', password: '' }}
                    validationSchema={Yup.object({
                        login: Yup.string()
                            .required('Un identifiant est nécessaire'),
                        password: Yup.string()
                            .required('Un mot de passe est nécessaire'),
                    })}
                    onSubmit={async ({ login, password }) => {
                        try {
                            let data = await ServiceAuth.login(login, password)
                            authDispatch({type:'setUser', payload: data[0]})
                            switch (data[0].role){
                                case "Administrateur":
                                    navigate('/accueiladmin')
                                    break;
                                case "Client":
                                    navigate('/accueilclient')
                                    break;
                                case "Testeur":
                                    navigate('/accueilclient')
                                    break;
                                default:
                                    navigate('/')
                            }
                        } catch (error) {
                            setAuthError(error)
                        } 
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            {state?.errMsg ?
                            state?.errMsg && <div className="alert alert-danger">{state?.errMsg}</div>
                            :
                            authError && <div className="alert alert-danger">{authError}</div>

                            }

                            {/* {state?.errMsg && <div className="alert alert-danger">{state?.errMsg}</div>} */}
                            {state?.success && <div className="alert alert-info">{state?.success}</div>}
                            {success && <div className="alert alert-info">{success}</div>}
                            {error && <div className="alert alert-danger">{error}</div>}
                            {/* {authError && <div className="alert alert-danger">{authError}</div>} */}
                            <div className="mb-3">
                                <label htmlFor="login" className="form-label">Identifiant</label>
                                <Field type="text" className="form-control" id="login" name="login" autoComplete="username"/>
                                <ErrorMessage name="login" component="div" className="alert alert-warning" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Mot de passe</label>
                                <Field type="password" className="form-control" id="password" name="password" autoComplete="current-password"/>
                                <ErrorMessage name="password" component="div" className="alert alert-warning" />
                            </div>
                            <div className="d-grid gap">
                                <button type="submit" className="btn btn-primary btn-expand" disabled={isSubmitting}>Valider</button>
                            </div>
                            <div className="d-grid gap" style={{marginTop: 5 + 'px'}}>
                                <Link to="/reset-password">Mot de passe oublié ?</Link>
                                <br/>
                                <p>
                                    <b>Vous n'avez pas reçu vos identifiants?</b><br/>
                                    Envoyer un mail au <a href='mailto:flux@socotex.fr'>SUPPORT</a> avec votre code client (présent sur vos documents de commande (ARC, BL, Facture, etc...)).
                                </p>
                                {/* <p><br/><br/>Cet espace commande est réservé aux clients de l'entreprise Socotex.
                                    Si vous avez un compte client : vous allez recevoir un e-mail : accès à socotex-espace-pro
                                        Si vous n'êtes pas encore client : rendez-vous sur notre formulaire 
                                        <a href="https://www.socotex.net/contact-socotex"> Contact </a>, vous serez contacté
                                        par le commercial en charge de votre secteur pour la mise en place de votre compte client.
                                </p> */}
                            </div>
                            <div style={{}}>
                                {/* <div>
                                    <b>Passez vos commandes de store Loggia et de parasols Le Pub sur l'espace pro Socotex !</b><br/>
                                    <img src={process.env.PUBLIC_URL + '/parasolPUB.PNG'} alt="Actualité Socotex" width="80%" height="fitContent"></img>
                                </div> */}
                                <div>
                                    <h3><b></b></h3>
                                    <img src={process.env.PUBLIC_URL + '/gifActu.gif'} alt="Actualité Socotex" width="80%" height="fitContent"></img>
                                </div>
                                {/* <div>
                                    <a href ='https://www.socotex.net/post/hissez-les-voiles-d-ombrage-sur-vos-balcons-et-terrasses-pour-vous-prot%C3%A9ger-du-soleil' target="_blank" rel='noreferrer noopener'>

                                        <b><br/>Des confections sur mesure pour habiller la façade du Central hôtel de Trouville-sur-Mer</b>

                                        <br/></a>
                                    <a href='https://www.socotex.net/post/des-confections-sur-mesure-pour-habiller-la-fa%C3%A7ade-du-central-h%C3%B4tel-de-trouville-sur-mer' target="_blank" rel='noreferrer noopener'>
                                        <img src={process.env.PUBLIC_URL + '/actuSocotex.gif'} alt="Actualité Socotex2" width="80%" height="fitContent"></img>
                                    </a>
                                </div> */}

                            </div>
                        </Form>
                    )}
                </Formik>
                
            </div>
            
        </div>
    )
}

export default Login